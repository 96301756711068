import { cn } from '~/libs/utils';

import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '~/components/ui/form';
import { InputWithIcon } from '~/components/ui/input';

import { useIsRequired } from '../../form-atom';
import type { FieldProps, HiddenProps, NumberProps, TextProps } from './types';

export type TextFieldProps = FieldProps & (TextProps | NumberProps | HiddenProps);

export const TextField = ({ label, helpText, name, defaultValue, className, type, ...props }: TextFieldProps) => {
  const isRequired = useIsRequired(name);
  const isHidden = type === 'hidden';

  return (
    <FormField
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormItem
          hidden={isHidden}
          aria-hidden={isHidden}
          className={cn(
            {
              hidden: isHidden,
              'opacity-60': isHidden,
              'cursor-not-allowed select-none [&_input]:cursor-not-allowed': isHidden,
            },
            className
          )}
        >
          <FormLabel>
            {label}
            {isRequired && <span className='text-destructive'> *</span>}
          </FormLabel>
          <FormControl>
            <InputWithIcon {...props} {...field} type={type} />
          </FormControl>
          {helpText && <FormDescription>{helpText}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
